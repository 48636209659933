import { Injectable } from '@angular/core';
import {JWT_ACCESS_TOKEN, JWT_REFRESH_TOKEN} from '../../../utils/constants';
import { Router } from '@angular/router';
import { AuthApi, UserApi } from '../../../api/services';
import {firstValueFrom, Observable} from 'rxjs';
import { LoginResponseDto } from '../../../api/models';
import {first, tap} from 'rxjs/operators';
import { GoogleAuthService } from '../google-auth/google-auth.service';

interface SetTokensPayload {
  accessToken: string;
  refreshToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private userApi: UserApi,
    private authApi: AuthApi,
    private router: Router,
    private googleAuthService: GoogleAuthService) { }

  login(email: string, password: string): Observable<LoginResponseDto> {
    return this.userApi.userControllerLogin({ body: { email, password } }).pipe(
      tap(({ accessToken, refreshToken }) => {
        this.setTokens({ accessToken, refreshToken });
      })
    );
  }

  async loginWithGoogle(isSignUp = false, freeTrial = false, role?): Promise<{ userId: string; }> {
    const tokenResponse = await this.googleAuthService.init(isSignUp);
    const clientAccessToken = tokenResponse.access_token;
    const { accessToken, refreshToken, uid } = await firstValueFrom(
      this.userApi.userControllerLoginWithGoogle({
        body: { accessToken: clientAccessToken, isSignUp, role: (role ? role : 'CUSTOMER') }, freeTrial
      })
    );
    this.setTokens({ accessToken, refreshToken });
    return { userId: uid };
  }

  async logout(): Promise<void> {
    if (this.isLoggedIn) {
      try {
        await firstValueFrom(this.authApi.authControllerLogOut());
      } catch {
        console.log('API Logout Failed');
      }
    }

    this.setTokens({ accessToken: '', refreshToken: '' });
    if (this.router.url !== '/login') {
      window.location.reload();
    }
  }

  get isLoggedIn(): boolean {
    return !!this.getAccessToken();
  }

  getAccessToken(): string {
    return localStorage.getItem(JWT_ACCESS_TOKEN);
  }

  getRefreshToken(): string {
    return localStorage.getItem(JWT_REFRESH_TOKEN);
  }

  setTokens({ accessToken, refreshToken }: SetTokensPayload): void {
    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
  }

  setAccessToken(value: string): void {
    localStorage.setItem(JWT_ACCESS_TOKEN, value);
  }

  setRefreshToken(value: string): void {
    localStorage.setItem(JWT_REFRESH_TOKEN, value);
  }

  refreshToken(): Observable<LoginResponseDto> {
    return this.authApi.authControllerRefreshToken().pipe(
      first(),
      tap(({ accessToken, refreshToken }) => {
        this.setTokens({ accessToken, refreshToken });
      }),
    );
  }

  getBeaconSignature() {
    return this.userApi.userControllerGetBeaconSignature()
  }
}
